import React from 'react'  
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import List from '../components/list'
import CommaSeparatedList from '../components/commaList'
import Img from 'gatsby-image'
import {Badge} from 'reactstrap'
import Helmet from 'react-helmet'

const GigTemplate = ({ data }) => {
  const gig = data.strapiGigs;
  const links = data.allStrapiLinks.edges;
  const artists = gig.artists;
  const venue = gig.venue;
  const poster = gig.poster.childImageSharp;

  return (
  <Layout>
  <Helmet
          title={`${gig.title} | Laz's Bootlegs`}
          meta={[
            { property: 'og:description', content: `${gig.title} in Larry's collection of bootlegs` },
            { name: 'keywords', content: `music, melbourne, ${venue.name}` },
            { property: 'og:title', content: `${gig.title} | Laz's Bootlegs`},
            { property: 'og:image', content: `${poster.fluid.src}`},
          ]}
        >
</Helmet>
    <h2>{gig.title}</h2>
    <p><strong>{gig.date}</strong></p>
    <div className="full-post" style={{ maxWidth: 500, marginBottom: 20 }}>
        <Img fluid={poster.fluid} />
    </div>
    <p>
      <Badge className="pill" tag={Link} to={`/venue/${venue.slug}`} color="secondary">{venue.name}</Badge>
    </p>
    <p>Artists: {artists.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/artist/${item.slug}`} color="secondary">{item.name}</Badge>
        ))}</p>
    <p>{gig.description}</p>
    <p>Links: {links.map((item , i) => (
              <Badge className="pill" key={i} href={item.node.url} color="primary">{item.node.artist.name}</Badge>
        ))}</p>
    
  </Layout>
  )}

export default GigTemplate

export const query = graphql`  
query GigTemplate($slug: String!) {
  strapiGigs(slug: {eq: $slug}) {
    title
    date(formatString: "DD MMMM YYYY")
    description
    slug
    venue {
      name
      slug
    }
    artists {
      name
      slug
    }
    poster {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }

  allStrapiLinks(
    filter:
      { gig: { slug: { eq: $slug } } }
    )
    {
    edges {
      node {
        url
        artist {
          name
        }
      }
    }
  }
}
`
